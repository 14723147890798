import React from 'react';
import styled from 'styled-components';
import aiProfileImage from './aiProfileImage.png'; // Replace with your actual image path

function AIProfileFeature() {
  return (
    <AIProfileSection>
      <TextContent>
        <h2>Introducing Stork AI Profiles</h2>
        <Subtitle>Your child’s growth, play, and nutrition - all in one place.</Subtitle>
        <p>
          With Stork AI Profiles, parenting just got smarter! Our AI-driven insights provide personalized, daily recommendations for your child, including growth trends, age-appropriate activities, and tailored goals for both play and nutrition. It’s like having a dedicated assistant focused on your child’s unique needs.
        </p>
        <Button>Explore Stork AI Profiles →</Button>
      </TextContent>
      <ImageContent>
        <FeatureImage src={aiProfileImage} alt="Stork AI Profile Feature" />
      </ImageContent>
    </AIProfileSection>
  );
}

const AIProfileSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 40px;
  background: #f9f9f9;
  border-radius: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContent = styled.div`
  max-width: 600px;
  margin-right: 20px;
  text-align: left;

  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #2e6a8e;

    @media (max-width: 768px) {
      font-size: 2rem;
      text-align: center;
    }
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #666666;
    line-height: 1.6;

    @media (max-width: 768px) {
      text-align: center;
    }
  }
`;

const Subtitle = styled.p`
  font-size: 1rem;
  color: #888;
  margin-top: -10px;
  margin-bottom: 20px;
  font-weight: 500;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Button = styled.button`
  background: #dd4912;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

  &:hover {
    background: #e49f6b;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-left: 0;
  }
`;

const FeatureImage = styled.img`
  width: 300px; /* Decreased the width */
  max-width: 100%;
  height: auto;
  border-radius: 20px;
  
  transform: rotate(-3deg); /* Slight angle for dynamic presentation */

  @media (max-width: 768px) {
    width: 70%; /* Ensures it remains responsive on smaller screens */
  }
`;


export default AIProfileFeature;
