import React from 'react';
import styled from 'styled-components';
import usersara from './sarakpic.jpg';
import useremily from './emilyj.jpg';
import usermichael from './michael.jpg';

function Reviews() {
  return (
    <ReviewsSection>
      <h2>Our Users Love Stork</h2>
      <ReviewCards>
        <ReviewCard>
          <Stars>★★★★★</Stars>
          <ReviewText>
            "Stork has made it so much easier for us to keep up with our newborn's needs. We love how convenient the deliveries are!"
          </ReviewText>
          <Profile>
            <ProfileImage src={useremily} alt="User 1" />
            <ProfileInfo>
              <strong>Emily J.</strong>
              <span>Mother of two</span>
            </ProfileInfo>
          </Profile>
        </ReviewCard>
        <ReviewCard>
          <Stars>★★★★★</Stars>
          <ReviewText>
            "With Stork, I never worry about running out of diapers or baby formula. It’s a lifesaver!"
          </ReviewText>
          <Profile>
            <ProfileImage src={usermichael} alt="User 2" />
            <ProfileInfo>
              <strong>Michael T.</strong>
              <span>Father of a newborn</span>
            </ProfileInfo>
          </Profile>
        </ReviewCard>
        <ReviewCard>
          <Stars>★★★★☆</Stars>
          <ReviewText>
            "Stork has been great for keeping my family prepared. The gifting feature made baby showers so much easier!"
          </ReviewText>
          <Profile>
            <ProfileImage src={usersara} alt="User 3" />
            <ProfileInfo>
              <strong>Sara K.</strong>
              <span>Aunt to a little one</span>
            </ProfileInfo>
          </Profile>
        </ReviewCard>
      </ReviewCards>
    </ReviewsSection>
  );
}

const ReviewsSection = styled.section`
  padding: 60px 20px;
  background: #ffffff;
  text-align: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 40px;
    color: #447c9d; /* Moody Blue */
  }
`;

const ReviewCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const ReviewCard = styled.div`
  background: #fbf8f3; /* Neutral Warmth */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: left;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
`;

const Stars = styled.div`
  font-size: 1.2rem;
  color: #dd4912; /* Pop of Orange */
  margin-bottom: 10px;
`;

const ReviewText = styled.p`
  font-size: 1rem;
  color: #666666; /* Light grey */
  margin-bottom: 20px;
  line-height: 1.5;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 1rem;
    color: #333333; /* Very Dark Grey */
  }

  span {
    font-size: 0.85rem;
    color: #999999; /* Lighter grey */
  }
`;

export default Reviews;
