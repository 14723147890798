import React, { useState } from 'react';
import styled from 'styled-components';
import logo from './storklogo.png';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Nav>
      <Logo>
        <LogoImage src={logo} alt="Stork Logo" />
      </Logo>
      <Hamburger onClick={toggleMenu}>
        {menuOpen ? <CloseIcon>&times;</CloseIcon> : <HamburgerIcon>&#9776;</HamburgerIcon>}
      </Hamburger>
      <NavLinks isOpen={menuOpen}>
        <NavLink href="#features" onClick={toggleMenu}>Features</NavLink>
        <NavLink href="#how-it-works" onClick={toggleMenu}>How It Works</NavLink>
        <NavLink href="#gifting" onClick={toggleMenu}>Stork Gifting</NavLink>
        <NavLink href="#reviews" onClick={toggleMenu}>Reviews</NavLink>
        <NavLink href="#contact" onClick={toggleMenu}>Contact</NavLink>
        <Button onClick={toggleMenu}>Get Started</Button>
      </NavLinks>
    </Nav>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #fbf8f3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 150px;
  height: auto;

  @media (max-width: 768px) {
    width: 120px;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #447c9d;

  @media (max-width: 768px) {
    display: block;
  }
`;

const HamburgerIcon = styled.span`
  font-size: 1.5rem;
`;

const CloseIcon = styled.span`
  font-size: 1.5rem;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fbf8f3;
    justify-content: center;
    align-items: center;
    gap: 30px;
    z-index: 999;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #447c9d;
  font-weight: 500;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #e49f6b;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Button = styled.button`
  background: #dd4912;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  font-weight: 500;

  &:hover {
    background: #e49f6b;
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 12px 24px;
    font-size: 1.2rem;
  }
`;

export default Header;
