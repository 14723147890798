import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function MissionSection() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Simulate a delay to trigger the animation (for a smoother effect)
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <MissionWrapper>
      <Label>OUR MISSION</Label>
      <MissionText isVisible={isVisible}>
        <p>
        Stork is dedicated to simplifying parenthood by delivering essential supplies with unmatched convenience and reliability. Our goal is to help new parents focus on what matters most.
        </p>
        <Author>Amaan Moledina -Founder of Stork</Author>
      </MissionText>
    </MissionWrapper>
  );
}

const MissionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px 20px;
  background: #fbf8f3; /* Neutral Warmth */
`;

const Label = styled.span`
  font-size: 0.85rem;
  font-weight: bold;
  color: #333333; /* Very Dark Grey */
  background: #dd4912; /* Pop of Orange */
  padding: 5px 10px;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const MissionText = styled.div`
  max-width: 800px;
  font-size: 1.5rem;
  color: #333333; /* Very Dark Grey */
  line-height: 1.5;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '0px' : '20px')});
  transition: opacity 1s ease, transform 1s ease;

  p {
    margin: 0 0 20px;
  }
`;

const Author = styled.p`
  font-size: 1rem;
  color: #666666; /* Light grey */
  margin-top: 10px;
`;

export default MissionSection;
