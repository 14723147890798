import React from 'react';
import styled from 'styled-components';
import shareIcon from './icons8-share-100.png';
import giftIcon from './icons8-gift-64.png';
import deliveryIcon from './icons8-deliver-60.png';
import giftingframe from './pixelcut-export.png'
// Import your images or icons here if you have any
// import shareIcon from './share.png'; // Replace with your actual image path

function GiftingFeature() {
  return (
    <GiftingWrapper>
      <Content>
        <h2>Share the Joy with Stork Gifting</h2>
        <p>
          Make it easy for friends and family to support your little one. Share a unique link, and they can send a personalized Stork Box filled with essentials right to your doorstep.
        </p>
        <Steps>
          <Step>
            <StepIcon>
              <img src={shareIcon} alt="Share Icon" />
            </StepIcon>
            <StepText>
              <strong>Step 1:</strong> Share your unique link with friends and family.
            </StepText>
          </Step>
          <Step>
            <StepIcon>
              <img src={giftIcon} alt="Gift Icon" />
            </StepIcon>
            <StepText>
              <strong>Step 2:</strong> They choose from a range of baby essentials to fill the Stork Box.
            </StepText>
          </Step>
          <Step>
            <StepIcon>
              <img src={deliveryIcon} alt="Delivery Icon" />
            </StepIcon>
            <StepText>
              <strong>Step 3:</strong> The Stork Box is delivered directly to your home, with love.
            </StepText>
          </Step>
        </Steps>
      </Content>
      <Visual>
          <img src={giftingframe} alt="Gifting Feature" style={{ maxWidth: '100%', height: 'auto' }} />
      </Visual>
    </GiftingWrapper>
  );
}

const GiftingWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 60px 20px;
  background: #f2f7fb; /* Light background */
`;

const Content = styled.div`
  max-width: 500px;
  text-align: center;

  h2 {
    font-size: 2rem;
    color: #447c9d; /* Moody Blue */
    margin-bottom: 20px;
  }

  p {
    font-size: 1.1rem;
    color: #666666; /* Light grey */
    margin-bottom: 30px;
    line-height: 1.6;
  }
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StepIcon = styled.div`
  width: 60px;
  height: 60px;
  
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
  }
`;

const StepText = styled.p`
  font-size: 1rem;
  color: #333333; /* Very Dark Grey */
  line-height: 1.4;
`;

const Visual = styled.div`
  max-width: 300px;
  
`;



export default GiftingFeature;
