import React from 'react';
import styled from 'styled-components';
import truckIcon from './icons8-delivery-truck-48.png';
import giftIcon from './icons8-gift-box-48.png';
import customizeIcon from './icons8-box-favorite-100.png';

function Features() {
  return (
    <FeaturesWrapper>
      <h2>Features</h2>
      <FeatureCards>
        <FeatureCard>
          <FeatureIcon>
            <img src={customizeIcon} alt="Customizable Boxes" />
          </FeatureIcon>
          <FeatureTitle>Customizable Boxes</FeatureTitle>
          <FeatureText>
            Tailor each Stork Box to your baby’s needs, selecting the perfect products just for them.
          </FeatureText>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon>
            <img src={truckIcon} alt="Timely Deliveries" />
          </FeatureIcon>
          <FeatureTitle>Timely Deliveries</FeatureTitle>
          <FeatureText>
            Receive all your baby’s essentials on time with reliable doorstep deliveries.
          </FeatureText>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon>
            <img src={giftIcon} alt="Easy Gifting" />
          </FeatureIcon>
          <FeatureTitle>Easy Gifting</FeatureTitle>
          <FeatureText>
            Share a link with loved ones so they can easily send a Stork Box to celebrate your little one.
          </FeatureText>
        </FeatureCard>
      </FeatureCards>
    </FeaturesWrapper>
  );
}

const FeaturesWrapper = styled.section`
  padding: 60px 20px;
  background: #f2f7fb; /* Light background */
  text-align: center;

  h2 {
    font-size: 2rem;
    color: #447c9d; /* Moody Blue */
    margin-bottom: 40px;
  }
`;

const FeatureCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const FeatureCard = styled.div`
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const FeatureIcon = styled.div`
  margin-bottom: 15px;

  img {
    width: 60px;
    height: 60px;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  color: #333333; /* Very Dark Grey */
  margin-bottom: 10px;
`;

const FeatureText = styled.p`
  font-size: 0.95rem;
  color: #666666; /* Light grey */
  line-height: 1.5;
`;

export default Features;
