import React, { useState } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import step1Image from './uishowcase1.png';
import step2Image from './homeui.png';
import step3Image from './shippingui.png';
import step4Image from './step4image.jpg';

function FeatureShowcase() {
  const [activeIndex, setActiveIndex] = useState(0);
  const steps = [
    {
      image: step2Image,
      title: 'Step 1: Select your Stork Product',
      text: 'Browse our curated selection of baby essentials ranging from newborn to toddler.',
    },
    {
      image: step1Image,
      title: 'Step 2: Build your Stork Box',
      text: 'Adjust quantities, select preferred brands, and customize your subscription plan.',
    },
    {
      image: step3Image,
      title: 'Step 3: Confirm Shipping and Place Order',
      text: 'Review your selections and place your order with secure payment options powered by Stripe.',
    },
    {
      image: step4Image,
      title: 'Step 4: Enjoy Your Stork Box',
      text: 'Your Stork Box will be delivered to your doorstep on your selected delivery date.',
    },
  ];

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrevious(),
    trackMouse: true,
  });

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex < steps.length - 1 ? prevIndex + 1 : 0));
  };

  const handlePrevious = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : steps.length - 1));
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <ShowcaseSection>
      <h2>How to Place an Order</h2>
      <CarouselContainer {...handlers}>
        <StepImage src={steps[activeIndex].image} alt={steps[activeIndex].title} />
        <Description>
          <h3>{steps[activeIndex].title}</h3>
          <p>{steps[activeIndex].text}</p>
        </Description>
      </CarouselContainer>
      <DotsContainer>
        {steps.map((_, index) => (
          <Dot
            key={index}
            active={index === activeIndex}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </DotsContainer>
    </ShowcaseSection>
  );
}

const ShowcaseSection = styled.section`
  padding: 60px 20px;
  text-align: center;
  background: #f9f9f9;
  min-height: 100vh;

  h2 {
    font-size: 2rem;
    margin-bottom: 40px;
    color: #447c9d;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const StepImage = styled.img`
  width: 100%;
  border-radius: 10px;
`;

const Description = styled.div`
  padding: 20px;
  text-align: center;

  h3 {
    color: #447c9d;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: #666666;
    line-height: 1.6;
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ active }) => (active ? '#dd4912' : '#ccc')};
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #e49f6b;
  }
`;

export default FeatureShowcase;
