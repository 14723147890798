import React from 'react';
import styled from 'styled-components';
import logo from './storknobackg.png';
import facebookIcon from './icons8-facebook-48.png';
import instagramIcon from './icons8-instagram-48.png';
import twitterIcon from './icons8-x-50.png';


function Footer() {
  return (
    <FooterSection>
      <FooterContent>
        <Logo>
          <img src={logo} alt="Stork Logo" />
        </Logo>
        <FooterLinks>
          <a href="#features">Features</a>
          <a href="#how-it-works">How It Works</a>
          <a href="#reviews">Reviews</a>
          <a href="#contact">Contact Us</a>
        </FooterLinks>
        <SocialIcons>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" />
          </a>
        </SocialIcons>
      </FooterContent>
      <p>&copy; {new Date().getFullYear()} Stork. All rights reserved.</p>
    </FooterSection>
  );
}

const FooterSection = styled.footer`
  background: #447c9d; /* Moody Blue */
  color: white;
  padding: 30px 20px;
  text-align: center;

  p {
    margin-top: 20px;
    font-size: 0.9rem;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const Logo = styled.div`
  img {
    height: 125px;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 15px;

  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;

    &:hover {
      color: #e49f6b; /* Dusty Orange */
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;

  img {
    height: 25px;
  }
`;

export default Footer;
