import React from 'react';
import styled from 'styled-components';
import babyimage from './babyimage.jpg';
import logo from './storklogo.png';

function Hero() {
  return (
    <HeroSection>
      <TextContent>
        <Logo src={logo} alt="Stork Logo" />
        <h1>Parenthood Made Simple</h1>
        <p>Delivering baby essentials to your doorstep, so you can focus on what truly matters.</p>
        <Button>Coming Soon to the App Store!</Button>
      </TextContent>
      <ImageContent>
        <PhoneImage src={babyimage} />
      </ImageContent>
    </HeroSection>
  );
}

const HeroSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(135deg, #a4d4c0, #94C4B1);

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 10px;
  }
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 150px;
    margin-bottom: 20px;
  }
`;

const TextContent = styled.div`
  max-width: 600px;
  margin-right: 20px;

  h1 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    color: #2e6a8e;

    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  }

  p {
    font-size: 1.3rem;
    margin-bottom: 20px;
    color: #555555;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Button = styled.button`
  background: #ff6f3c;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    background: #ff8550;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ImageContent = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PhoneImage = styled.img`
  width: 400px;
  height: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  @media (max-width: 768px) {
    width: 80%;
    max-width: 300px;
  }
`;

export default Hero;
