import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Mission from './components/MissionSection';
import FeatureShowcase from './components/Showcase';
import GiftingFeature from './components/GiftingFeature';
import Reviews from './components/Reviews';
import Footer from './components/Footer';
import AIProfileFeature from './components/AIProfileFeature';
import ContactSection from './components/ContactSection';

function App() {
  return (
    <Container>
      <Header />
      <Section id="hero">
        <Hero />
      </Section>
      <Section id="features">
        <Features />
      </Section>
      <Section id="how-it-works">
        <FeatureShowcase /> 
      </Section>
      <Section id="gifting">
        <GiftingFeature />
      </Section>
      <Section id="ai-profile">
        <AIProfileFeature />
      </Section>
      <Section id="mission">
        <Mission />
      </Section>
      <Section id="reviews">
        <Reviews />
      </Section>
      <Section id="contact">
        <ContactSection />
      </Section>
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  font-family: 'Arial', sans-serif;
  background: #fbf8f3; /* Neutral Warmth */
`;

const Section = styled.section`
  padding: 100px 20px; /* Offset for fixed header */
  text-align: center;
`;

export default App;
